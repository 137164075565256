import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio Applications" />
    <h1>
      <u>Applications</u>
    </h1>
    <a
      href="http://mindfulmatters.us-east-1.elasticbeanstalk.com/"
      target="_blank"
    >
      <img src={require("../images/mindful.png")} alt="logo" border="1" />
    </a>
    <h2>
      <u>Mindful Matters</u>
    </h2>
    Link to Repo:{" "}
    <a href="https://github.com/kphung29/mvp_mindful_matters" target="_blank">
      <img
        src={require("../images/github.svg")}
        alt="logo"
        height="30"
        width="30"
      />
    </a>
    <h3>
      This is a mood daily mood monitoring app where you can add current mood,
      activities, and journal snippets. Full stack application integrates
      authentication using Google or Facebook via Firebase and has built out
      custom API that stores data to Mongo Atlas cloud database instance.{" "}
    </h3>
    <h4>
      {" "}
      Tech Used: React, Express, MongoDB, Mongoose ORM, Chart.js, Semantic.UI,
      Firebase, Mongo Atlas, AWS EC2, AWS EBS
    </h4>
    <br />
    <img src={require("../images/twitch.png")} alt="logo" border="1" />
    <h2>
      <u>Twitch Video Service</u>
    </h2>
    Link to Repo:{" "}
    <a
      href="https://github.com/zwei-zwei/kevin-categories-component-sql"
      target="_blank"
    >
      <img
        src={require("../images/github.svg")}
        alt="logo"
        height="30"
        width="30"
      />
    </a>
    <h3>
      An app for clients to upload and stream video content. Deployed service
      and database instances into AWS EC2 for horizontal scaling. Integrated AWS
      Elastic Load Balancer for autoscaling leveraging AMI and Docker
      technologies.{" "}
    </h3>
    <h4>
      {" "}
      Tech Used: React, Webpack, Node.js, Express, PostgreSQL, Sequelize,
      Artillery.io, Loader.io, AWS EC2, AWS ELB, AWS CloudWatch, nginx, pm2
    </h4>
    <br />
    <img src={require("../images/CryptoQuotes.png")} alt="logo" border="1" />
    <h2>
      <u>Crypto Quotes</u>
    </h2>
    Link to Repo:{" "}
    <a
      href="https://github.com/App-Architects/kevin-mini-apps-2/tree/master/challenge_2"
      target="_blank"
    >
      <img
        src={require("../images/github.svg")}
        alt="logo"
        height="30"
        width="30"
      />
    </a>
    <h3>
      This is a cryptocurrency app that charts Bitcoin's last 6 months price
      history via CoinDesk API. Additional features include the top 6
      crytocurrency holdings and shows detailed data via a custom ticker on
      bottom of page.{" "}
    </h3>
    <h4>
      {" "}
      Tech Used: React, Express, Chart.js, Semantic.UI, CoinDesk API, CoinLib.io
      API, AWS EC2, AWS EBS
    </h4>
  </Layout>
);

export default Portfolio;
